:root{
    --displacement: -60svh;
}


    .slideIn{
        animation: slideIn cubic-bezier(.37,.11,0,.83);
        animation-timeline: view()!important;
        animation-range-start: cover !important;
        animation-range-end: 20svh !important;
        animation-fill-mode: forwards !important;
        transform-origin: bottom center !important;

    }
    
    @keyframes slideIn {
        from{
            transform: translateX(var(--displacement)) scale(.5);
            opacity: 0;
        }
        to{
            transform: translateX(0svw) scale(1);

            opacity: 1;
        }
    }
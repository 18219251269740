.SVGCurveDivider {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.SVGCurveDivider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 125px;
}

.SVGCurveDivider .shape-fill {
    fill: #1D1D1D;
}

   .fadeIn{
        animation: fadeInAnim cubic-bezier(.37,.11,0,.83);
        animation-timeline: view()!important;
        animation-range-start: cover!important;
        animation-range-end: 50svh!important;
        animation-fill-mode: forwards!important;
        
        
    }
    
    @keyframes fadeInAnim {
        from{
            scale:0.7;
            transform: translate(0px,100px) ;
            opacity: 0;
        }
        to{
            scale:1 ;
            transform: translate(0px,0px);
            opacity: 1;
        }
    }



.bottom-curve-divider {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.bottom-curve-divider svg {
    position: relative;
    display: block;
    width: calc(100% + 1.3px);
    height: 68px;
}

.bottom-curve-divider .shape-fill {
    fill: #1D1D1D;
}

   .rotateIn{
        animation: rotateIn cubic-bezier(.37,.11,0,.83);
        animation-timeline: view()!important;
        animation-range-start: cover !important;
        animation-range-end: 50svh !important;
        animation-fill-mode: forwards !important;
        transform-origin: bottom center !important;
        
        
    }
    
    @keyframes rotateIn {
        from{
            transform: rotateZ(20deg);
            opacity: 0;

        }
        to{
            transform: rotateZ(0deg);
            opacity: 1;
        }
    }

.flip-exit {
    transform: rotateY(0deg);
}

.flip-exit-active{
    transform: rotateY(-180deg);
    transition: transform 500ms;
    transition-timing-function:cubic-bezier(.2,.04,.73,.31);

}

.flip-exit-done{
    transform: rotateY(-180deg);
}


.flip-enter {
    transform: rotateY(-180deg);
}

.flip-enter-active{
    transform: rotateY(0deg);
    transition: transform 500ms ;
    transition-timing-function: cubic-bezier(.2,.04,.73,.31);
}

.flip-enter-done{
    transform: rotateY(0deg);
}